@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";

#side {}
.gSide {
    $_: &;
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: calc(100vh - 100px);
    z-index: 3;
    transition: .2s;
    @include tabletP {
        top: auto;
        bottom: 0;
        width: 100%;
        height: auto;
    }
    &[aria-hidden = "true"] {
        @include tabletP {
            opacity: 0;
            pointer-events: none;
        }
    }
    &__group01 {
        background-color: rgba($mainColor, .8);
        border-radius: 10px 0px 0px 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: center;
        width: 310px;
        padding: 30px 20px 30px;
        @include tabletP {
            display: none;
        }
    }
    &__tel {
        display: inline-flex;
        align-items: center;
        color: #fff;
        @include fz(28);
        font-family: $fontMincho;
        font-weight: bold;
        line-height: math.div(40,28);
        &:not(:only-child) {
            margin-bottom: 13px;
        }
        &::before {
            content: "";
            background: url(#{$cmnPath}icon_tel.svg) no-repeat center / contain;
            width: 30px;
            height: 30px;
            margin-right: 9px;
        }
    }
    &__btnlist {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }
    &__group02 {
        background: $mainColor;
        display: none;
        width: 100%;
        @include tabletP {
            display: block;
        }
    }
    &__list {
        display: flex;
    }
    &__item {
        flex: 1;
        position: relative;
        &::after {
            content: "";
            background-color: #fff;
            display: none;
            position: absolute;
            top: 10px;
            bottom: 10px;
            right: 0;
            width: 1px;
        }
        &:not(:last-child) {
            &::after {
                display: block;
            }
        }
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        @include fz(12);
        font-weight: 500;
        line-height: math.div(18,12);
        text-decoration: none;
        min-height: $sideHeightSp;
        padding: 10px 8px 10px;
    }
    &__image {
        flex-shrink: 0;
        margin-right: 5px;
    }
}