@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";
/*
#overview
modList
*/

/*
#styleguide
modList　modIndent

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
<ul class="modIndent02">
    <li>１.　テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>２.　リスト</li>
</ul>
```
*/

.modListNormal { margin: 0 0 0 2em; }

ul.modListNormal {
	li{
		&:before {
			content: "\0030fb";
			display: inline-block;
			margin-left:-2em;
			text-align:center;
			width:2em;
		}
	}
}

ol.modListNormal li { list-style:decimal; }

/* indent */
.modIndent01 li {
	margin: 0 0 0 1em;
	text-indent: -1em;
}

.modIndent02 li {
	margin: 0 0 0 2.5em;
	text-indent: -2.5em;
}

.modDlForm{
	margin:0 0 20px;
	dt{
		clear:both;
		float:left;
		padding:33px 0 20px 63px;
		position:relative;
		width:16em;
		@include tabletP{
			float:none;
			padding:33px 0 0 63px;
			width:auto;
		}
		&.required:before,
		&.any:before{
			color:#FFF;
			@include fz(11);
			left:0;
			padding:2px 10px;
			position:absolute;
			top:33px;
		}
		&.required:before{
			background:$colorStrong;
			content:"必須";
		}
		&.any:before{
			background:#999999;
			content:"任意";
		}
	}
	dd{
		border-bottom:solid 1px #ccc;
		padding:10px 0;
		padding-left:17em;
		@include tabletP{
			padding:10px 0;
		}
		.parts{
			padding:10px 0;
			textarea,
			input[type="text"],
			input[type="email"] {
				width:100%;
			}
			&.radio_inline,
			&.check_inline {
				> div{
					display:inline-block;
				}
			}
			&.name{
				display:flex;
				justify-content:space-between;
				>div{
					width:48.5%;
				}
			}
			&.post,
			&.tel {
				display:flex;
				.hyphen{
					padding:10px 10px 0 10px;
					@include sphoneP{
						padding:10px 5px 0 5px;
					}
				}
			}
			&.password{
				input{
					max-width:300px;
					width:100%;
					@include sphoneP{
						max-width:none;
					}
				}
				.text{
					padding:10px 0;
				}
			}
		}
	}
	.validationError{
		color:$colorStrong;
	}
}

.modListBreadcrumb {
	background-color: #FAF8F4;
	margin-bottom: 90px;
	padding: 11px 0 11px;
	@include tabletP {
		margin-bottom: 30px;
		padding: 12px 0 8px;
	}
	&__inner {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		@include fz(14);
		line-height: math.div(28,14);
		@include tabletP{
			@include fz(12);
			line-height: math.div(28,12);
		}
		&:not(:last-child) {
			&::after {
				content:'\3E';
				margin: 0 15px;
			}
		}
		&.-home {
			&::before{
				display: none;
			}
		}
	}
	&__link {
		color: $mainBgColor;
	}
}//.modListBreadcrumb

.modListInfo {
	$_: &;
	border-color: $mainColor;
	border-style: solid;
	border-width: 1px 0;
	@include tabletP {
		margin: 0 -#{$containerPadding}px;
	}
	&__item {
		&:not(:last-child) {
			border-bottom: 1px dotted #999;
		}
	}
	&__link {
		display: block;
		position: relative;
		text-decoration: none;
		min-height: 150px+30px*2;
		padding: 36px 100px 36px 210px;
		@include tabletP {
			min-height: 100px+15px*2;
			padding: 15px 70px 15px 130px;
		}
		&::before,
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
		}
		&::before {
			background-color: #faf8f4;
			border: 2px solid #ffbe6e;
			border-radius: 50%;
			box-shadow: 0px 3px 4px rgba(199, 168, 146, 0.16);
			right: 31px;
			width: 45px;
			height: 45px;
			@include tabletP {
				right: 15px;
			}
		}
		&::after {
			border-top: 2px solid $mainColor;
			border-right: 2px solid $mainColor;
			width: 10px;
			height: 10px;
			right: 50px;
			transform: rotate(45deg);
			@include tabletP {
				right: 34px;
			}
		}
	}
	&__image {
		background-position: center;
		background-size: cover;
		border-radius: 10px;
		position: absolute;
		top: 30px;
		left: 30px;
		width: 150px;
		height: 150px;
		@include tabletP {
			top: 15px;
			left: 15px;
			width: 100px;
			height: 100px;
		}
		img {
			border-radius: 0;
			box-shadow: none;
		}
	}
	&__detail {
		@include tabletP {
			margin-top: -5px;
		}
	}
	&__date {
		color: $mainColor;
		margin-bottom: 6px;
		@include tabletP {
			@include fz(14);
			line-height: math.div(28,14);
			margin-bottom: 0;
		}
	}
	&__title {
		@include fz(18);
		font-weight: 500;
		line-height: math.div(28,18);
		margin-bottom: 18px;
		@include tabletP {
			@include fz(16);
			line-height: math.div(28,16);
			margin-bottom: 0;
		}
	}
	&__txt {
		@include tabletP {
			display: none;
		}
	}
}//.modListInfo

/*
#styleguide
modListStaff

```
```
*/
.modListStaff {
    $_: &;
	display: flex;
	justify-content: space-between;
	margin-bottom: -60px;
	@include tabletP {
		display: block;
		margin-bottom: -30px;
	}
	&__item {
		width: math.percentage(math.div(460px,980px));
		@include tabletP {
			width: auto;
		}
		&:not(:last-child) {
			margin-bottom: 60px;
			@include tabletP {
				margin-bottom: 30px;
			}
		}
	}
	&__image {
		margin-bottom: 24px;
		@include tabletP {
			margin-bottom: 20px;
		}
		img {
			width: 100%;
		}
	}
	&__name {
		@include fz(22);
		font-family: $fontMincho;
		font-weight: 400;
		line-height: math.div(28,22);
		text-align: center;
		@include tabletP {
			@include fz(16);
			line-height: math.div(28,16);
		}
		&Bold {
			font-size: math.div(30px,22px)*1em;
			@include tabletP {
				font-size: math.div(20px,16px)*1em;
			}
		}
	}
}//.modListStaff

/*
#styleguide
modListStep01

```
```
*/
.modListStep01 {
	border-color: #ffbe6e;
	border-style: solid;
	border-width: 2px 0;
	margin-bottom: 90px;
	padding: 30px 0 30px;
	@include tabletP {
		margin-bottom: 30px;
		padding: 15px 0 15px;
	}
	&__inner {
		display: flex;
		justify-content: center;
	}
	&__item {
		position: relative;
		color: #D0D0D0;
		text-align: center;
		flex-basis: 100%;
		@include tabletP {
			flex-basis: auto;
			@include fz(14);
			line-height: math.div(28,14);
		}
		&::after {
			content: "";
			border-top: 2px solid #D0D0D0;
			border-right: 2px solid #D0D0D0;
			display: none;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 10px;
			height: 10px;
			margin: auto;
			transform: rotate(45deg);
			@include tabletP {
				position: static;
				margin: 0 16px;
			}
		}
		&[aria-current] {
			color: $mainColor;
		}
		&:not(:last-child) {
			&::after {
				display: inline-block;
			}
		}
	}
}//.modListStep01

/*
#styleguide
modListStep02

```
```
*/
.modListStep02 {
    $_: &;
	position: relative;
	&:not(:first-child) {
		margin-top: 52px;
		@include tabletP {
			margin-top: 30px;
		}
	}
	& &__item {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		&:not(:last-child) {
			&::before {
				content: "";
				background-color: #FFBE6E;
				position: absolute;
				top: 126px * .5;
				bottom: 0;
				left: 126px * .5 - 2px;
				width: 2px;
				@include tabletP {
					top: 90px * .5;
					bottom: 0;
					left: 90px * .5 - 2px;
				}
			}
		}
	}
	& &__step,
	& &__detail {
		flex-basis: auto !important;
	}
	& &__step {
		background: #FAF8F4;
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		position: relative;
		color: $mainColor;
		@include fz(18);
		font-weight: 600;
		font-family: $fontMincho;
		line-height: 1;
		width: 126px;
		height: 126px;
		margin-right: 60px;
		@include tabletP {
			@include fz(13);
			width: 90px;
			height: 90px;
			margin-right: 15px;
		}
		&::before,
		&::after {
			content: "";
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
		}
		&::before {
			border: 14px solid #fff;
		}
		&::after {
			border: 2px solid #FFBE6E;
		}
		&Num {
			font-size: math.div(32px,18px)*1em;
			@include tabletP {
				font-size: math.div(24px,13px)*1em;
			}
		}
	}
	& &__detail {
		margin-left: 0;
		padding-top: 15px;
		@include tabletLmin {
			
		}
		@include tabletP {
			padding-top: 0;
		}
		@at-root #{$_} #{$_}__item:not(:last-child) #{$_}__detail {
			&::after {
				content: "";
				background: linear-gradient(to right, #999, #999 1px, transparent 1px, transparent 5px) repeat-x 0 0 / 5px 1px;
				display: block;
				height: 1px;
				margin: 43px 0 28px 0;
				@include tabletP {
					margin: 30px 0 30px 0;
				}
			}
		}
	}
	& &__title {
		color: $mainColor;
		@include fz(20);
		font-weight: 600;
		font-family: $fontMincho;
		line-height: 1.4;
		margin-bottom: 15px;
		@include tabletP {
			@include fz(18);
			margin-bottom: 10px
		}
	}
	p {
		line-height: math.div(28,16);
	}
	& &__txtTel,
	a {
		color: $mainColor;
		font-size: math.div(18px,16px)*1em;
		font-weight: bold;
		text-decoration: underline;
	}
}//.modListStep02

/*
#styleguide
modListPagination

```
```
*/
.modListPagination {
	$_: &;
	text-align: center;
	margin-top: 60px;
	@include tabletP {
		margin-top: 30px;
	}
	&__list {
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: center;
		position: relative;
	}
	&__group {
		display: flex;
		justify-content: center;
		margin: 0 -#{$containerPadding}px;
		padding: 0 #{45px+30px+$containerPadding};
		#{$_}__item {
			&:not(:last-child) {
				margin-right: 30px;
			}
			&:nth-child(3) {
				@include tabletP {
					margin-right: 0;
				}
			}
			&:nth-child(n+4) {
				@include tabletP {
					display: none;
				}
			}
		}
	}
	&__item {
		background: #faf8f4;
		border: 2px solid #ffbe6e;
		box-shadow: 0px 3px 6px rgba(200, 189, 182, 0.16);
		display: flex;
		align-items: center;
		justify-content: center;
		color: $mainColor;
		@include fz(22);
		font-family: $fontMincho;
		font-weight: 600;
		letter-spacing: 0.05em;
		line-height: math.div(31,22);
		text-align: center;
		min-width: 45px;
		height: 45px;
		&.-bgNone {
			background: none;
			border-color: transparent;
			box-shadow: none;
		}
		&.-prev,
		&.-prev02 {
			#{$_}__link {
				&::before {
					content: "";
					display: inline-block;
					border-bottom: 2px solid $mainColor;
					border-left: 2px solid $mainColor;
					width: 12px;
					height: 12px;
					transform: translateX(3px) rotate(45deg);
				}
			}
		}
		&.-next,
		&.-next02 {
			#{$_}__link {
				&::after {
					content: "";
					display: inline-block;
					border-top: 2px solid $mainColor;
					border-right: 2px solid $mainColor;
					width: 12px;
					height: 12px;
					transform: translateX(-3px) rotate(45deg);
				}
			}
		}
		&.-prev,
		&.-next {
			position: absolute;
			top: 0;
		}
		&.-prev02,
		&.-next02 {
			flex-shrink: 0;
			@include fz(16);
			font-family: $baseFontFamily;
			font-weight: normal;
			height: 54px;
			@include tabletP {
				position: relative;
				width: 143px;
				margin: auto;
			}
		}
		&.-prev {
			left: -#{$containerPadding}px;
		}
		&.-next {
			right: -#{$containerPadding}px;
		}
		&.-prev02 {
			@include tabletP {
				margin-right: 15px;
			}
			#{$_}__link {
				padding: 0 25px 0 16px;
				@include tabletP {
					padding: 0 25px;
				}
				&::before {
					margin-right: 10px;
					@include tabletP {
						position: absolute;
						top: 6px;
						bottom: 0;
						left: 16px;
						margin: auto;
					}
				}
			}
		}
		&.-next02 {
			@include tabletP {
				margin-left: 15px;
			}
			#{$_}__link {
				padding: 0 16px 0 25px;
				@include tabletP {
					left: 16px;
					padding: 0 25px;
				}
				&::after {
					margin-left: 10px;
					@include tabletP {
						position: absolute;
						top: 6px;
						bottom: 0;
						right: 16px;
						margin: auto;
					}
				}
			}
		}
	}
	&__btn {
		width: 250px;
		margin: 0 60px;
		@include tabletP {
			order: 1;
			width: 315px;
			margin: 30px auto 0;
		}
	}
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		color: inherit;
		text-decoration: none;
		width: 100%;
		height: 100%;
		&:not(a) {
			color: #999;
		}
	}
}//.modListPagination

/*
#styleguide
modListNumber01

```
```
*/
.modListNumber01 {
	$_: &;
	margin-bottom: 120px;
	@include tabletP {
		margin-bottom: 60px;
	}
	&__item {
		counter-increment: modListNumber01;
		display: flex;
		justify-content: space-between;
		@include tabletP {
			display: block;
		}
		&:not(:last-child) {
			margin-bottom: 75px;
			@include tabletP {
				margin-bottom: 30px;
			}
		}
		& > figure {
			width: calc(100% - 490px);
			@include tabletP {
				text-align: center;
				width: auto;
				margin-bottom: 20px;
			}
			@at-root #{$_} #{$_}__item.has-media-on-the-right > figure {
				order: 1;
			}
			img {
				@include tabletP {
					width: 100%;
				}
			}
		}
		& > div {
			width: 430px;
			margin: 0 0 auto;
			@include tabletP {
				width: auto;
			}
		}
	}
	& &__imageSp {
		display: none;
		@include tabletP {
			display: block;
			margin: 0 0 20px;
		}
		figure {
			float: none;
			margin: 0;
		}
	}
	&__title {
		display: flex;
		align-items: center;
		color: $mainColor;
		@include fz(20);
		font-family: $fontMincho;
		font-weight: 600;
		letter-spacing: 0;
		line-height: math.div(36,20);
		margin-bottom: 27px;
		@include tabletP {
			@include fz(18);
			letter-spacing: 0.05em;
			line-height: math.div(27,18);
			margin-bottom: 11px;
		}
		&::before {
			content: counter(modListNumber01, decimal-leading-zero);
			background-color: #faf8f4;
			border: 2px solid #ffbe6e;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: math.div(32px,20px)*1em;
			width: 70px;
			height: 70px;
			margin-right: 30px;
			@include tabletP {
				font-size: math.div(24px,18px)*1em;
				width: 45px;
				height: 45px;
				margin-right: 15px;
			}
		}
	}
}//.modListNumber01

/*
#styleguide
modListMedical

```
```
*/
.modListMedical {
	$_: &;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 60px;
	@include tabletP {
		max-width: 400px;
		margin: 0 auto 5px;
	}
	&__item {
		width: math.percentage(math.div(220px,980px));
		@include tabletP {
			width: 165px;
			margin-bottom: 25px;
		}
	}
	&__link {
		display: block;
		text-decoration: none;
	}
	&-icon {
		border-radius: 50%;
		position: relative;
		text-align: center;
		margin-bottom: 20px;
		padding-top: 100%;
		@include tabletP {
			margin-bottom: 15px;
		}
		&::after {
			content: "";
			border: 2px solid #FFBE6E;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
		}
		&__inner {
			border-radius: 50%;
			background-color: #FAF8F4;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: calc(100% - 28px);
			height: calc(100% - 28px);
			margin: auto;
		}
		&__image {
			flex-shrink: 0;
			width: math.percentage(math.div(100px,188px));
			margin: 0 auto 10px;
			@include tabletP {
				width: 64px;
			}
			img {
				border-radius: 0;
				box-shadow: none;
			}
			#{$_}-icon.-type02 & {
				@include tabletP {
					margin-bottom: 4px;
				}
			}
		}
		&__title {
			color: $mainColor;
			@include fz(18);
			font-family: $fontMincho;
			font-weight: 600;
			line-height: math.div(26,18);
			@include tabletP {
				@include fz(16);
				line-height: math.div(23,16);
			}
			&Sub {
				font-size: 0.6112em;
			}
			#{$_}-icon.-type02 & {
				line-height: 1.2;
				@include tabletP {
					line-height: 1;
				}
			}
		}
	}
	&__txt {
		@include tabletP {
			@include fz(14);
			line-height: math.div(25,14);
		}
	}
}//.modListMedical

/*
#styleguide
modListFacility

```
```
*/
.modListFacility {
	$_: &;
	& > * {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 30px;
		@include tabletP {
			max-width: 400px;
			margin: 0 auto 0;
		}
		&::before {
			content: "";
			order: 1;
			display: block;
		}
	}
	& > *::before,
	& &__item {
		width: math.percentage(math.div(306px,980px));
		margin-bottom: 30px;
		@include tabletP {
			width: 165px;
		}
	}
	& &__link {
		display: block;
		text-decoration: none;
	}
	& &-icon {
		border-radius: 50%;
		position: relative;
		text-align: center;
		margin: 0;
		padding-top: 100%;
		&::after {
			content: "";
			border: 2px solid #FFBE6E;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
		}
		figure {
			border-radius: 50%;
			background-color: #FAF8F4;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: calc(100% - 30px);
			height: calc(100% - 30px);
			margin: auto;
			overflow: hidden;
			@include tabletP {
				width: calc(100% - 16px);
				height: calc(100% - 16px);
			}
			img {
				border-radius: 0;
				box-shadow: none;
				width: 100%;
			}
			figcaption {
				background-color: rgba($mainColor, .8);
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				bottom: 0;
				left: 0;
				color: #fff;
				font-family: $fontMincho;
				width: 100%;
				height: math.percentage(math.div(111px,276px));
				padding-bottom: 5%;
				@include tabletP {
					@include fz(14);
					line-height: math.div(20,14);
					height: 74px;
				}
			}
		}
	}
	& &__txt {
		margin: 30px 0 30px;
		@include tabletP {
			@include fz(14);
			line-height: math.div(25,14);
			margin: 20px 0 0;
		}
	}
}//.modListFacility

/*
#styleguide
modListDl01

```
```
*/
.modListDl01 {
    $_: &;
	&.-type02 {
		@include tabletPmin {
			display: flex;
			justify-content: space-between;
		}
	}
	&.-sizeS {
		@include fz(14);
	}
	&__group {
		&:not(:last-child) {
			margin-bottom: 29px;
			@at-root #{$_}.-type02 & {
				@include tabletP {
					margin-bottom: 45px;
				}
			}
		}
		@at-root #{$_}.-type02 & {
			display: flex;
			flex-direction: column;
			@include tabletPmin {
				width: calc(33.3333% - 40px);
				margin-bottom: 0;
			}
		}
		&.-type02 {
			&:not(:last-child) {
				@include tabletPmin {
					margin-bottom: 60px;
				}
			}
		}
	}
	&__title {
		background-color: rgba(#fff, .7);
		border: 1px solid #FFBE6E;
		display: inline-block;
		color: $mainColor;
		@include fz(22);
		font-family: $fontMincho;
		font-weight: 600;
		letter-spacing: 0.1em;
		line-height: math.div(31,22);
		margin-bottom: 8px;
		padding: 5px 37px 4px;
		@include tabletP {
			@include fz(18);
			line-height: math.div(26,18);
			margin-bottom: -2px;
			padding: 5px 41px 4px;
		}
		@at-root #{$_}.-type02 & {
			display: block;
			text-align: center;
			margin-bottom: 30px;
			@include tabletP {
				margin-bottom: 20px;
			}
		}
		@at-root #{$_}.-sizeS & {
			@include fz(16);
		}
	}
	&__detail {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	&__item {
		display: flex;
		align-items: center;
		position: relative;
		padding: 20px 0 19px;
		@include tabletP {
			display: block;
		}
		@at-root #{$_}.-sizeS & {
			padding: 15px 0 15px;
		}
		&:not(:first-child) {
			&::after {
				content: "";
				background: linear-gradient(to right, #999999, #999999 1px, transparent 1px, transparent 5px) repeat-x 0 0 / 5px 1px;
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
			}
		}
	}
	&__date {
		width: 170px;
		@include tabletP {
			@include fz(14);
			line-height: 1.4;
			width: auto;
			margin-bottom: 7px;
		}
		&:empty {
			display: none;
		}
	}
	&__txt {
		width: 100%;
	}
	&__btn {
		margin-top: 30px;
		@include tabletP {
			margin-top: 20px;
		}
		> * {
			@include tabletPmin {
				max-width: none;
			}
		}
	}
}//.modListDl01

/*
#styleguide
modListDl02

```
```
*/
.modListDl02 {
	&:not(:first-child) {
		margin-top: 30px;
	}
	&__group {
		&:not(:last-child) {
			margin-bottom: 30px;
			@include tabletP {
				margin-bottom: 20px;
			}
		}
	}
	&__title {
		background-color: $mainColor;
		color: #fff;
		display: inline-block;
		@include fz(14);
		letter-spacing: 0.05em;
		line-height: 1.4;
		text-align: center;
		min-width: 90px;
		margin-bottom: 15px;
		padding: 5px 10px;
		@include tabletP {
			@include fz(12);
			min-width: 85px;
		}
		&__item {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
}//.modListDl02

/*
#styleguide
modListGallery

```
```
*/
.modListGallery {
    $_: &;
	&:not(:first-child) {
		margin-top: 60px;
		@include tabletP {
			margin-top: 30px;
		}
	}
	&:not(:last-child) {
		margin-bottom: 30px;
		@include tabletP {
			margin-bottom: 15px;
		}
	}
	:root & {
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			&:not(:last-child) {
				margin-bottom: 30px;
				@include tabletP {
					margin-bottom: 15px;
				}
			}
			li {
				margin-bottom: 30px;
				@include tabletPmin {
					flex: 1;
				}
				@include tabletP {
					flex-grow: 0;
					width: calc(50% - 7.5px);
					margin-bottom: 15px;
				}
				&:not(:last-child) {
					@include tabletPmin {
						margin-right: 31px;
						&:nth-child(1):nth-last-child(2),
						&:nth-child(2):nth-last-child(1) {
							margin-right: 60px;
						}
					}
					@include tabletP {
						margin-right: 0;
					}
				}
				&:only-child,
				&:nth-child(3):nth-last-child(1) {
					@include tabletP {
						width: 100%;
					}
				}
				img {
					width: 100%;	
					@include tabletP {
						box-shadow: none;
					}
				}
			}
		}
	}
}//.modListGallery

/*
#styleguide
modListAnchor

```
```
*/
.modListAnchor {
    $_: &;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -7.5px (90px-15px);
	@include tabletP {
		margin-bottom: (30px-15px);
	}
	li {
		width: 20%;
		margin-bottom: 15px;
		padding: 0 7.5px;
		@include max(1010) {
			width: 25%;
		}
		@include max(810) {
			width: 33.3333%;
		}
		@include sphoneP {
			width: 50%;
		}
		&:nth-child(1):nth-last-child(4),
		&:nth-child(2):nth-last-child(3),
		&:nth-child(3):nth-last-child(2),
		&:nth-child(4):nth-last-child(1) {
			@include min(811) {
				width: 25%;
			}
		}
		&:nth-child(1):nth-last-child(3),
		&:nth-child(2):nth-last-child(2),
		&:nth-child(3):nth-last-child(1) {
			@include min(810) {
				width: 30%;
			}
			@include sphoneP {
				width: 100%;
			}
		}
		&:nth-child(1):nth-last-child(3) {
			@include sphonePmin {
				margin-left: 0;
				margin-right: auto;
			}
		}
		&:nth-child(2):nth-last-child(2) {
			@include sphonePmin {
				margin-left: auto;
				margin-right: auto;
			}
		}
		&:nth-child(3):nth-last-child(1) {
			@include sphonePmin {
				margin-left: auto;
				margin-right: 0;
			}
		}
	}
	a {
		background-color: #FAF8F4;
		border: 2px solid #FAF8F4;
		display: block;
		position: relative;
		line-height: math.div(28,16);
		text-align: center;
		text-decoration: none;
		padding: 10px 16px 12px;
		@include tabletP {
			@include fz(14);
			line-height: math.div(28,14);
			padding: 9px 16px 9px;
		}
		&::after {
			content: "";
			border-bottom: 2px solid $mainColor;
			border-right: 2px solid $mainColor;
			display: block;
			position: absolute;
			top: -6px;
			bottom: 0;
			right: 20px;
			width: 10px;
			height: 10px;
			margin: auto;
			transform: rotate(45deg);
			@include tabletP {
				top: -2px;
				right: 15px;
			}
		}
		&:hover {
			background-color: $mainColor;
			border-color: #FFBE6E;
			opacity: 1;
			color: #fff;
			&::after {
				border-bottom-color: #fff;
				border-right-color: #fff;
			}
		}
	}
}//.modListAnchor

/*
#styleguide
modListContact01

```
```
*/
.modListContact01 {
    $_: &;
	@include tabletPmin {
		display: flex;
		justify-content: space-between;
	}
	@include tabletP {
		display: block;
	}
	& &__item {
		border: 2px solid #FFBE6E;
		display: flex;
		flex-basis: auto !important;
		flex-direction: column;
		justify-content: space-between;
		max-width: 460px;
		width: math.percentage(math.div(460px,980px));
		padding: 30px 0 40px;
		@include tabletP {
			width: auto;
			margin: 0 auto;
			padding: 25px 0 30px;
		}
		&:not(:last-child) {
			@include tabletP {
				margin-bottom: 18px;
			}
		}
	}
	& &__title {
		font-family: "Noto Serif JP";
		font-weight: 600;
		font-size: 20px;
		line-height: 36px;
		text-align: center;
		color: $mainColor;
		@include fz(20);
		font-weight: bold;
		font-family: $fontMincho;
		letter-spacing: 0;
		line-height: math.div(36,20);
		text-align: center;
		margin-bottom: 30px;
		@include tabletP {
			@include fz(18);
			line-height: math.div(32,18);
		}
	}
	p {
		@include fz(14);
		letter-spacing: 0.1em;
		line-height: math.div(20,14);
		text-align: center;
		&:last-child {
			margin-top: 20px;
		}
	}
	& &__txtTel,
	a {
		color: $mainColor;
		font-size: math.div(24px,14px)*1em;
		font-weight: bold;
		text-decoration: underline;
	}
}//.modListContact01

/*
#styleguide
modListContact02

```
```
*/
.modListContact02 {
    $_: &;
	@include tabletPmin {
		display: flex;
		justify-content: space-between;
	}
	&__item {
		max-width: 460px;
		width: math.percentage(math.div(460px,980px));
		@include tabletP {
			width: auto;
			margin: 0 auto;
		}
		&:only-child {
			margin: 0 auto;
		}
		&:not(:last-child) {
			@include tabletP {
				margin-bottom: 22px;
			}
		}
	}
	&__link {
		background: $mainColor;
		border: 2px solid #ffbe6e;
		box-shadow: 0px 3px 6px rgba(109, 30, 10, 0.16);
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		text-decoration: none;
		height: 100%;
		padding: 18px 20px 16px;
		@include tabletP {
			min-height: 82px;
			padding: 8px 20px 7px;
		}
		&::after {
			content: "";
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 20px;
			width: 10px;
			height: 10px;
			margin: auto;
			transform: rotate(45deg);
		}
	}
	&__txt {
		color: #fff;
		@include fz(18);
		letter-spacing: 0.05em;
		line-height: math.div(28,18);
		text-align: center;
		@include tabletP {
			@include fz(16);
			line-height: math.div(28,16);
		}
		&Tel {
			font-size: math.div(22px,18px)*1em;
			font-weight: bold;
			@include tabletP {
				font-size: math.div(20px,16px)*1em;
			}
		}
	}
}//.modListContact02

/*
#styleguide
modListXXX

```
```
*/
.modListXXX {
    $_: &;
}//.modListXXX
