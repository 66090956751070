@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";
/*
#overview
modForm
*/

/*
#styleguide
***

```
```
*/
.modForm {
    $_: &;
    color: #232323;
    &__txt {
        text-align: center;
        margin-bottom: 1em;
        @include tabletP {
            text-align: left;
        }
    }
    &__txtComplete {
        color: $mainColor;
        @include fz(20);
        font-family: $fontMincho;
        font-weight: 600;
        line-height: math.div(28,20);
        text-align: center;
        margin-bottom: 60px;
        @include tabletP {
            @include fz(18);
            letter-spacing: 0.1em;
            line-height: math.div(26,18);
            text-align: left;
            margin-bottom: 13px;
        }
    }
    &__list {
        margin-top: 64px;
        @include tabletP {
            margin-top: 30px;
        }
    }
    &-item {
        &:not(:last-child) {
            margin-bottom: 34px;
        }
        &__title {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @include fz(18);
            font-weight: bold;
            line-height: math.div(28,18);
            margin-bottom: 19px;
            &Required {
                display: inline-block;
                background-color: $mainColor;
                color: #fff;
                @include fz(12);
                font-family: "Noto Sans JP";
                font-weight: normal;
                padding: 0 12px 0 13px;
                margin-left: 15px;
            }
        }
        &__detail {
            display: flex;
            flex-wrap: wrap;
        }
        &__txt {
            &Error {
                color: $colorStrong;
                letter-spacing: 0.05em;
                line-height: math.div(28,16);
                width: 100%;
            }
        }
        &__radio,
        &__checkbox {
            @include tabletP {
                width: 100%;
            }
            &:not(:last-child) {
                margin-right: 60px;
                @include tabletP {
                    margin: 0 0 16px;
                }
            }
        }
    }
    &__btnbox {
        display: flex;
        max-width: 620px;
        margin: 60px auto 0;
        @include tabletP {
            display: block;
        }
        & #{$_}__btn {
            flex-basis: 100%;
            &:not(:last-child) {
                @include tabletP {
                    margin-bottom: 20px;
                }
            }
        }
    }
}