@use "../layout/footer";
@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";

/*
information
*/
$id:information;
$imgPath:'../img/#{$id}/';
body#page_#{$id} {
    .myHead {
        $_: ".myHead";
        border-bottom: 1px solid #ff8c00;
        margin-bottom: 60px;
        padding-bottom: 28px;
        @include tabletP {
            margin-bottom: 30px;
            padding-bottom: 14px;
        }
        &__date {
            color: $mainColor;
            margin-bottom: 16px;
            @include tabletP {
                @include fz(14);
                line-height: math.div(28,14);
                margin-bottom: 10px;
            }
        }
        &__title {
            @include fz(24);
            line-height: math.div(28,24);
            @include tabletP {
                @include fz(18);
                line-height: math.div(28,18);
            }            
        }
    }//.myHead

    .myDetail {
        $_: ".myDetail";
        &__imagelist {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 30px;
            @include tabletP {
                margin-bottom: 15px;
            }
        }
        &__image {
            margin-bottom: 30px;
            @include tabletPmin {
                flex: 1;
            }
            @include tabletP {
                width: calc(50% - 7.5px);
                margin-bottom: 15px;
            }
            &:not(:last-child) {
                margin-right: 31px;
                @include tabletP {
                    margin-right: 0;
                }
            }
            &.-sizeMaxSp {
                @include tabletP {
                    width: 100%;
                }
            }
        }
        &__txt {
            &:not(:last-child) {
                margin-bottom: math.div(28,16)*1em;
                @include tabletP {
                    margin-bottom: math.div(15,16)*1em;
                }
            }
        }
    }//.myDetail
}