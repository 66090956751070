@use "../mixin" as *;
@use "../setting" as *;

@charset "utf-8";
/*
#overview
modTable
*/

/*
#styleguide
modTable01

```
```
*/
.modTable01 {
    table {
        border-top: 1px solid #e5e5e5;                
        border-left: 1px solid #e5e5e5;                
        width: 100%;
        tr {
            th, td {
                border-bottom: 1px solid #e5e5e5;                
                border-right: 1px solid #e5e5e5;
                padding: .8em;         
            }
            &:nth-child(odd) {
                background-color: #faf8f4;
            }
        }
    }
}//.modTable01

/*
#styleguide
modTable02

```
recruit「募集要項」で使用
```
*/
.modTable02 {
    &:not(:last-child) {
        margin-bottom: 60px;
        @include tabletP {
            margin-bottom: 30px;
        }
    }
    table {
        border-color: $mainColor;
        border-style: solid;
        border-width: 1px 0;
        @include sphoneP {
            &,
            & thead, & tbody, & tfoot, 
            & tr,
            & tr th, & tr td {
                display: block;
            }
        }
        tr {
            & > * {
                vertical-align: middle;
                padding: 28px 3.75em 29px 3.75em;
                @include tabletP {
                    padding: 12px 2em 15px 2em
                }
                @include sphoneP {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            th {
                background-color: #FAF8F4;
                border-bottom: 1px solid #F2EEE7;
                color: #444;
                letter-spacing: 0.1em;
                font-weight: bold;
                width: 13.4em;
                @include tabletP {
                    border-bottom: none;
                    width: 10em;
                }
                @include sphoneP {
                    width: auto;
                    text-align: center;
                }
                &:last-child {
                    border-bottom: none
                }
            }
            td {
                border-bottom: 1px dotted #999999;
                @include tabletP {
                    border-bottom: none;
                }
            }
            &:last-child {
                & > * {
                    border-bottom: none;
                }
            }
        }    
    }
}//.modTable02

/*
#styleguide
modTableXX

```
```
*/