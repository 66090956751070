@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";
#contents {}
.gContents {
    padding: $headerHeight 0 210px;
    @include tabletL {
        padding-top: $headerHeightSp;
    }
    @include tabletP {
        padding-bottom: 90px;
    }
    @at-root #page_top & {
        padding-top: 0;
    }
}