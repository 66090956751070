@charset "utf-8";

// font
$baseFont: 16;
$baseLetterSpacing: .05em;
$baseLineHeight: 1.75;
$baseColor: #333;
$baseLinkColor: #333;
$mainColor: #FF8C00;
$mainBgColor: #EE7F00;

// color
$colorStrong: #FF0000; //強調系（エラーなど）
$colorBlue: #3498DB;
$colorGreen: #1ABC9C;

// brake-point
$bpSphoneP: 600px;
$bpTabletP: 768px;
$bpTabletL: 992px;
$baseWidthWide: 1200px;

// container-padding
$containerPadding: 15;

// container-size
$container-sm: #{$containerPadding * 2 + 750px};
$container-md: #{$containerPadding * 2 + 980px};
$container-lg: #{$containerPadding * 2 + 1170px};

// header-size
$headerHeight: 160px;
$headerHeightSp: 71px;

// side-size
$sideHeightSp: 57px;

// font-family
$baseFontFamily : 'Noto Sans JP', -apple-system, BlinkMacSystemFont, "メイリオ", Meiryo, "Hiragino Kaku Gothic ProN", sans-serif;
$fontAlpha : Arial, Helvetica, sans-serif;
$fontMincho : 'Noto Serif JP', "游明朝体", "Yu Mincho", 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', serif;

// path
$cmnPath:'../img/common/';