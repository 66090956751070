@use "mixin" as *;
@use "setting" as *;
@use 'sass:math';

@charset "utf-8";

/* link
------------------------------------------------------------*/
a {
	color: $baseLinkColor;
	text-decoration: none;
}
a,button{
	&:hover {
		opacity:0.8;
		@include tabletL {
			opacity:1;
		}
	}
}

/* Fonts
------------------------------------------------------------*/
body{
	color: $baseColor;
	@include fz;
	font-family:$baseFontFamily;
	letter-spacing:$baseLetterSpacing;
	line-height:$baseLineHeight;
	overflow-wrap : break-word;
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	//IE11以下は別のフォントを指定する場合
	// &.IE {
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}

input,button,textarea,select{
	color: $baseColor;
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	font-size: 100%;
	//IE11以下は別のフォントを指定する場合
	// .IE &{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}

/*
#styleguide
container

```
<div class="container">
左右にpaddingつきます
</div>
```
*/
.container {
	margin-left: auto;
	margin-right: auto;
	max-width:$container-md !important;
	padding-left:  ($containerPadding)+px;
	padding-right: ($containerPadding)+px;
}
.modPcOnly {
	@include tabletP {
		display:none;
	}
}

.modSpOnly {
	@include tabletPmin {
		display:none;
	}
}

.modInlineBlock {	// 2020-10-05 追加
	&.-pcOnly {
		@include tabletPmin {
			display: inline-block;
		}
	}
	&.-spOnly {
		@include tabletP {
			display: inline-block;
		}
	}
	&:not([class *= "Only"]) {
		display: inline-block;
	}
}

br{
	&.pc{
		@include tabletP{
			display: none;
		}
	}
	&.sp{
		@include tabletPmin{
			display: none;
		}
	}
}

/* 2020-10-05 slickと干渉するのでコメ消し
[role="tabpanel"] {
	&[aria-hidden="true"] {
		display: none;
	}
	&[aria-hidden="false"] {
		display: block;
	}
}
*/
