@use "../layout/footer";
@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";
/*
#overview
modTitle
*/

/*
#styleguide
modTitle01

```
```
*/
.modTitle01 {
    $_: &;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $mainColor;
    @include fz(30);
    font-family: $fontMincho;
    font-weight: 600;
    letter-spacing: 0.1em;
    line-height: math.div(44,30);
    margin-bottom: 60px;
    @include tabletP {
        @include fz(20);
        line-height: math.div(29,20);
        text-align: center;
        margin-bottom: 30px;
    }
    &::before,
    &::after {
        content: "";
        display: block;
        width: 60px;
        height: 60px;
        @include tabletP {
            width: 58px;
            height: 58px;
        }
    }
    &::before {
        background: url(#{$cmnPath}point_title01.svg) no-repeat 0 0 / contain;
        margin-right: 15px;
        @include tabletP {
            background-size: 58px;
            margin-right: 5px;
        }
    }
    &::after {
        background: url(#{$cmnPath}point_title02.svg) no-repeat right 0 / contain;
        margin-left: 15px;
        @include tabletP {
            background-size: 58px;
            margin-left: 5px;
        }
    }
}//.modTitle01

/*
#styleguide
modTitle02

```
```
*/
.modTitle02 {
    border-bottom: 1px solid $mainColor;
    @include fz(24);
    font-weight: 600;
    font-family: $fontMincho;
    letter-spacing: 0.05em;
    line-height: math.div(28,24);
    margin-bottom: 60px;
    padding-bottom: 26px;
    @include tabletP {
        @include fz(19);
        line-height: math.div(28,19);
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
}//.modTitle02

/*
#styleguide
modTitlePage

```
```
*/
.modTitlePage {
    $_: &;
    background-position: center;
    background-size: cover;
    color: #fff;
    @include fz(38);
    font-family: $fontMincho;
    font-weight: 600;
    letter-spacing: 0.05em;
    line-height: 1.4;
    text-shadow: 0px 3px 10px #1c0f00;
    text-align: center;
    padding: 94px 10px 93px;
    @include tabletP {
        @include fz(24);
        padding: 31px 10px 31px;
    }
    &__word {
        display: block;
        &.-small {
            font-size: math.div(26px, 38px) * 1em;
        }
    }

    @each $var in (access,dr,contact,covid19,entry,facility,information,medical,policy,recruit,syokaki,preparation) {
        @at-root #page_#{$var} & {
            background-image: url(../img/#{$var}/bg_title.jpg);
            @include tabletP {
                background-image: url(../img/#{$var}/bg_title_sp.jpg);
            }
        }
        $_var2: "";
        @if($var == syokaki) {
//            $_var2: (case);
        }
        @each $var2 in $_var2 {
            @at-root #page_#{$var}_#{$var2} & {
                background-image: url(../img/#{$var}/#{$var2}/bg_title.jpg);
                @include tabletP {
                    background-image: url(../img/#{$var}/#{$var2}/bg_title_sp.jpg);
                }
            }
        }
    }
}//.modTitlePage

/*
#styleguide
modTitleIntro01

```
```
*/
.modTitleIntro01 {
    color: $mainColor;
    @include fz(20);
    font-weight: bold;
    margin-bottom: 1rem;
}//.modTitleIntro01