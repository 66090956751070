@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";
#header {
    @at-root #page_top & {
        @include tabletLmin {
            box-shadow: 0px 3px 6px rgba(200, 189, 182, 0.16);
            transition: .3s;
            &:not([aria-hidden = "false"]) {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}
.gHeader {
    $_: ".gHeader";
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    @include print{
        display: none;
    }
    &__container {
        padding: 0 100px 0 64px;
        @include max(1200) {
            padding-right: 80px;
        }
        @include tabletL {
            padding: 0 0 0 #{$containerPadding}px;
        }
    }
    &__inner {
        height: $headerHeight;
        padding: 30px 0 30px;
        @include tabletL {
            padding: 0;
            height: $headerHeightSp;
        }
    }
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        @include tabletL {
            height: inherit;
            margin-bottom: 0;
        }
    }
    &-title {
        font-family: $fontMincho;
        text-align: center;
        margin-top: -5px;
        @include tabletP {
            margin-top: -6px;
        }
        a {
            color: inherit;
            display: block;
            text-decoration: none;
        }
        &__txt01 {
            @include fz(14);
            font-weight: 600;
            line-height: math.div(28,14);
            @include tabletL {
                @include fz(10);
                line-height: math.div(28,10);
            }
        }
        &__txt02 {
            display: block;
            color: $mainColor;
            @include fz(28);
            font-weight: bold;
            line-height: math.div(28,28);
            margin-top: 5px;
            @include tabletL {
                @include fz(18);
                line-height: math.div(28,18);
                margin-top: -3px;
            }
        }
    }
    &-nav {
        display: flex;
        justify-content: flex-end;
        @include tabletL {
            display: none;
        }
        &__inner {
            display: flex;
        }
        &__item {
            position: relative;
            @include fz(14);
            line-height: math.div(28,14);
            &:not(:last-child) {
                margin-right: 15px;
                padding-right: 15px;
                &::after {
                    content: "";
                    background-color: #FFBE6E;
                    display: block;
                    position: absolute;
                    top: 6px;
                    bottom: 4px;
                    right: -1px;
                    width: 1px;
                    margin: auto;
                }
            }
        }
        &__link {
            text-decoration: none;
        }
    }
}//.gHeader

.gNav {
    $_: ".gNav";
    @include tabletLmin {
        display: flex !important;
        justify-content: flex-end;
    }
    @include tabletL {
        background-color: $mainBgColor;
        display: none;
        position: fixed;
        top: $headerHeightSp;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: auto;
    }
    &__inner {
        @include tabletL {
            padding-bottom: 40px;
        }
    }
    &__list {
        display: flex;
        @include tabletL {
            flex-wrap: wrap;
            max-width: 400px;
            margin: 0 auto 20px;
        }
    }
    &-item {
        position: relative;
        @include fz(14);
        letter-spacing: 0;
        line-height: 1.4;
        @include tabletL {
            color: #fff;
            width: 50%;
        }
        &.-spOnly {
            @include tabletLmin {
                display: none;
            }
        }
        &::before {
            content: "";
            border-bottom: 1px dotted #fff;
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 200%;
            height: 0;
        }
        &::after {
            content: "";
            background-color: #FFBE6E;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 1px;
            margin: auto;
            @include tabletL {
                background-color: #fff;
                top: 30px;
                bottom: 30px;
                right: -1px;
            }
        }
        &:not(:last-child) {
            @include tabletLmin {
                margin-right: 15px;
                padding-right: 15px;
            }
        }
        &:nth-child(odd) {
            &::before {
                @include tabletL {
                    display: block;
                }
            }
        }
        &:nth-child(even) {
            &::after {
                @include tabletL {
                    display: none;
                }
            }
        }
        &:nth-last-child(2) {
            @include tabletLmin {
                margin-right: 0;
                padding-right: 0;
            }
            &::after {
                @include tabletLmin {
                    display: none;
                }
            }
        }
        &__link {
            display: flex;
            align-items: flex-start;
            color: inherit;
            text-align: center;
            text-decoration: none;
            @include tabletL {
                display: block;
                color: inherit !important;
                padding: 30px 15px 33px;
            }
            &::before {
                content: "";
                background-position: center;
                background-size: contain;
                display: block;
                flex-shrink: 0;
                width: 20px;
                height: 20px;
                margin-right: 15px;
                transform: translateY(4px);
                @include tabletL {
                    width: 40px;
                    height: 40px;
                    margin: 0 auto 22px;
                }
                @for $_i from 1 to 10 {
                    $_ii: $_i; 
                    @if($_ii < 10) {
                        $_ii : '0' + $_ii;
                    }
                    @at-root #{$_}-item.-item#{$_ii} & {
                        background-image: url(#{$cmnPath}icon_nav#{$_ii}.svg);
                        @include tabletL {
                            background-image: url(#{$cmnPath}icon_nav#{$_ii}_sp.svg);
                        }
                    }
                }
            }
            &-txt {
                @include tabletLmin {
                    padding: 5px 0 5px;
                }
            }
            &-word {
                display: inline-block;
                @include tabletPmin {
                    @include max(1200) {
                        display: block;
                    }
                }
                &.-small {
                    font-size: math.div(13px, 14px) * 1em;
                }
            }

            @each $var in (dr) {
                @at-root #page_#{$var} &.-page_#{$var} {
                    color: $mainBgColor;
                }
            }
        }
    }
    &__tel {
        display: none;
        @include tabletL {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            @include fz(28);
            font-family: $fontMincho;
            font-weight: bold;
            line-height: math.div(40,28);
            margin-bottom: 30px;
            &::before {
                content: "";
                background: url(#{$cmnPath}icon_tel.svg) no-repeat center / contain;
                width: 30px;
                height: 30px;
                margin-right: 15px;
            }
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
    &__btnlist {
        @include tabletLmin {
            display: none;
        }
    }
    &__btn {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
    &__btnlist02 {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        width: calc(100% - 35px);
        max-width: 340px;
        margin: 0 auto 21px;
        @include tabletLmin {
            display: none;
        }
    }
}//.gNav

.gNavToggle {
    $_: ".gNavToggle";
    background: $mainColor;
    border: none;
    outline: none;
    display: none;
    position: relative;
    width: 71px;
    height: 100%;
    margin: 0 0 0 auto;
    z-index: 3;
    @include tabletL {
        display: block;
    }
    &__icon {
        background: url(#{$cmnPath}icon_menu_open.svg) no-repeat center / contain;
        display: block;
        width: 35px;
        height: 35px;
        margin: 0 auto -8px;
        @at-root #{$_}[aria-expanded="true"] & {
            background-image: url(#{$cmnPath}icon_menu_close.svg);
        }
    }
    &__txt {
        color: #fff;
        @include fz(12);
        font-family: $fontMincho;
        line-height: 1;
        text-align: center;
        &::before {
            content: "menu";
            @at-root #{$_}[aria-expanded="true"] & {
                content: "close";
            }
        }
    }
}//.gNavToggle

.gNavOverlay {
    $_: ".gNavOverlay";
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    cursor: pointer;
    transition: opacity .2s;
    &[aria-hidden="false"] {
        opacity: 1;
        height: 100%;
    }
    @include tabletLmin {
        display: none !important;
    }
}//.gNavOverlay
