@use "setting" as *;
@use "sass:math";


@charset "utf-8";

@mixin pc {
	@media only screen and (max-width: $baseWidthWide) {
		@content;
	}
}

@mixin pcmin {
	@media only screen and (min-width: #{$baseWidthWide + 1}) {
		@content;
	}
}

@mixin tabletL {
	@media only screen and (max-width: $bpTabletL) {
		@content;
	}
}
@mixin tabletLmin {
	@media only screen and (min-width: #{$bpTabletL + 1}) {
		@content;
	}
}

@mixin tabletP {
	@media only screen and (max-width: $bpTabletP) {
		@content;
	}
}

@mixin tabletPmin {
	@media only screen and (min-width: #{$bpTabletP + 1}) {
		@content;
	}
}

@mixin sphoneP {
	@media only screen and (max-width: $bpSphoneP) {
		@content;
	}
}

@mixin sphonePmin {
	@media only screen and (min-width: #{$bpSphoneP + 1}) {
		@content;
	}
}

@mixin max($max) {
	@media only screen and (max-width: #{$max}px) {
		@content;
	}
}

@mixin min($min) {
	@media only screen and (min-width: #{$min}px) {
		@content;
	}
}

@mixin print {
  @media print{
    @content;
  }
}

// webfont icon
@mixin iconFont() {
	font-family: FontAwesome;
	line-height:1;
}

// clearfix
@mixin clearfix() {
	&:after {
		clear:both;
		content:".";
		display:block;
		height:0;
		visibility:hidden;
	}
}

// font-size
@mixin fz($size: $baseFont, $important:false) {
	//IE8以下はpx指定にする場合
	//font-size: if($important, $size + px!important, $size + px);
	font-size: if($important, (math.div($size,16)) + rem!important, (math.div($size,16)) + rem);
}

@function get_vw($size, $viewport){
	$rate: math.div(100,$viewport);
	@return $rate * $size * 1vw;
}
@mixin fz_vw($font_size:10,$viewport:750){
	font-size: get_vw($font_size,$viewport);
}
@mixin vw($property:font-size,$size:10,$viewport:750){
	#{$property}: get_vw($size,$viewport);
}

@mixin animation($animation-name,$s,$easing,$count,$end:none,$delay:0) {
	animation: $animation-name;
	animation-delay: $delay;
	animation-duration:$s;
	animation-fill-mode: $end;
	animation-iteration-count:$count;
	animation-timing-function: $easing;
}

@mixin accessibilityHidden {
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
