@use "../layout/footer";
@use "../mixin" as *;
@use "../setting" as *;
@use "information";
@use 'sass:math';

@charset "utf-8";

/*
top
*/
information.$id:top;
information.$imgPath:'../img/#{information.$id}/';
body#page_#{information.$id} {
    .myMv {
        $_: ".myMv";
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
        position: relative;
        height: 826px;
        margin-bottom: 30px;
        padding-top: 68px;
        @include tabletP {
            box-shadow: none;
            height: auto;
            margin-top: $headerHeightSp;
            margin-bottom: 60px;
            padding-top: 0;
        }
        &__bglist {
            position: absolute;
            top: 0;
            bottom: 0;
            line-height: 0;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            @include tabletP {
                height: get_vw(228,375);
            }
            * {
                height: 100%;
            }
            .slick-track {
                display: flex;
            }
            .slick-slide {
                position: relative;
                height: auto;
                & > div {
                    height: 100%;
                }
            }      
        }
        &__bg {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 100%;
            @for $_i from 1 to 10 {
                $_ii: $_i; 
                @if($_ii < 10) {
                    $_ii : '0' + $_ii;
                }
                &.-bg#{$_ii} {
                    background-image: url(#{information.$imgPath}bg_mv#{$_ii}.jpg);
                    @include tabletP {
                        background-image: url(#{information.$imgPath}bg_mv#{$_ii}_sp.jpg);
                    }
                }
            }
        }
        &__wrapper {
            position: relative;
            z-index: 2;
        }
        &__catch01 {
            color: #224f7b;
            @include fz(45);
            font-weight: 600;
            font-family: $fontMincho;
            letter-spacing: 0.05em;
            line-height: math.div(80,45);
            text-align: center;
            text-shadow: 0px 3px 10px #fff;
            margin-bottom: 52px;
            @include tabletL {
                @include fz(40);
            }
            @include tabletP {
                display: flex;
                align-items: center;
                justify-content: center;
                @include fz(24);
                line-height: math.div(42,24);
                text-align: center;
                height: get_vw(228,375);
                margin-bottom: 0;
            }
        }
        &__catch02 {
            color: #224f7b;
            @include fz(30);
            font-weight: 700;
            font-family: $fontMincho;
            letter-spacing: 0.05em;
            line-height: math.div(80,45);
            text-align: center;
            text-shadow:
                0px 0px 5px #fff,
                0px 0px 5px #fff,
                0px 0px 5px #fff,
                0px 0px 15px #fff,
                0px 0px 15px #fff,
            ;
            margin-top: 40px;
            @include tabletP {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                @include fz(15);
                line-height: math.div(42,24);
                text-align: center;
                height: get_vw(228,375);
                width: 100%;
                margin-top: 0;
                padding-bottom: 20px;
            }
        }
    }//.myMv

    .myMenu {
        $_: ".myMenu";
        margin-bottom: 90px;
        @include tabletP {
            display: none;
        }
        &__head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 46px;
        }
        &-title {
            font-family: $fontMincho;
            text-align: center;
            margin-top: -5px;
            a {
                color: inherit;
                display: block;
                text-decoration: none;
            }
            &__txt01 {
                @include fz(12);
                font-weight: 600;
                line-height: math.div(28,12);
            }
            &__txt02 {
                display: block;
                color: $mainColor;
                @include fz(24);
                font-weight: bold;
                line-height: math.div(28,24);
                margin-top: 5px;
            }
        }
        &-nav {
            display: flex;
            justify-content: flex-end;
            &__inner {
                display: flex;
            }
            &__item {
                position: relative;
                @include fz(14);
                line-height: math.div(28,14);
                &:not(:last-child) {
                    margin-right: 15px;
                    padding-right: 15px;
                    &::after {
                        content: "";
                        background-color: #FFBE6E;
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 2px;
                        right: -1px;
                        width: 1px;
                        margin: auto;
                    }
                }
            }
            &__link {
                text-decoration: none;
            }
        }
        &-gNav {
            $_gNav: ".myMenu-gNav";
            @include tabletP {
                display: none;
            }
            &__list {
                display: flex;
            }
            &-item {
                position: relative;
                @include fz(14);
                line-height: 1.4;
                text-align: center;
                width: 25%;
                padding: 0 15px;
                &::before,
                &::after {
                    content: "";
                    background-color: #FFBE6E;
                    display: block;
                    position: absolute;
                    top: 6px;
                    bottom: 4px;
                    width: 1px;
                    margin: auto;
                }
                &::before {
                    left: -1px;
                }
                &::after {
                    right: -1px;
                }
                &:not(:first-child) {
                    &::before {
                        display: none;
                    }
                }
                &__link {
                    display: block;
                    color: inherit;
                    text-align: center;
                    text-decoration: none;
                    &::before {
                        content: "";
                        background-position: center;
                        background-size: contain;
                        display: block;
                        flex-shrink: 0;
                        width: 40px;
                        height: 40px;
                        margin: 0 auto 20px;
                        @for $_i from 1 to 10 {
                            $_ii: $_i; 
                            @if($_ii < 10) {
                                $_ii : '0' + $_ii;
                            }
                            @at-root #{$_gNav}-item.-item#{$_ii} #{$_gNav}-item__link::before {
                                background-image: url(#{$cmnPath}icon_nav#{$_ii}.svg);
                            }
                        }
                    }
                    &-word {
                        display: inline-block;
                        &.-small {
                            font-size: math.div(13px, 14px) * 1em;
                        }
                    }
                }
            }
        }
    }//.myMenu

    .myAntivirus {
        $_: ".myAntivirus";
        margin-bottom: 120px;
        @include tabletP {
            margin-bottom: 30px;
        }
    }//.myAntivirus

    .myInfo {
        $_: ".myInfo";
        margin-bottom: 120px;
        @include tabletP {
            margin-bottom: 60px;
        }
        &__btn {
            margin-top: 60px;
            @include tabletP {
                margin-top: 30px;
            }
        }
    }//.myInfo

    .myGreeting {
        $_: ".myGreeting";
    }//.myGreeting

    .myFeature {
        $_: ".myFeature";
        margin-bottom: 120px;
        @include tabletP {
            margin-bottom: 60px;
        }
    }//.myFeature

    .myMedical {
        $_: ".myMedical";
        margin-bottom: 120px;
        @include tabletP {
            margin-bottom: 30px;
        }
        &__detail {
            margin-top: 90px;
            @include tabletP {
                margin-top: 60px;
            }
        }
    }//.myMedical

    .myFacility {
        $_: ".myFacility";
        &__detail {
            margin-bottom: 60px;
            @include tabletP {
                margin-bottom: 30px;
            }
        }
    }//.myFacility

    .myAboutVaccine {
        $_: ".myAboutVaccine";
        $_color: #dc3545;
        margin-bottom: 120px;
        @include tabletP {
            margin-bottom: 30px;
        }
        strong {
            color: $_color;
            font-weight: bold;
        }
        &__title {
            @include tabletPmin {
                margin-bottom: 30px;
            }
        }
        &-info {
            &__title {
                font-weight: bold;
                background-color: $_color;
                display: inline-block;
                color: #fff;
                font-weight: bold;
                padding: 0.5em 1.6em 0.6em;
                @include tabletP {
                    padding: 0.5em 1.5em 0.6em;
                }
            }
            &__txt {
                border: 2px solid $_color;
                margin-bottom: 1.8em;
                padding: 1em 1.6em 1.2em;
                @include tabletP {
                    padding: 1em 1.5em 1.2em;
                }
            }
        }
        &__tel {
            margin-top: 30px;
        }
    }//.myAboutVaccine
}