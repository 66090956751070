@use "mixin" as *;
@use "setting" as *;

@charset "utf-8";


/* form
------------------------------------------------------------*/
textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
select{
	border:solid 1px #C4C4C4;
	padding:15px 20px 15px;
	width: 100%;
	&.-error {
		background-color:rgba(255,242,242,1.00);
		border-color: $colorStrong;
	}
	@each $var in (-webkit-input-placeholder,-moz-placeholder,-ms-input-placeholder) {
		$_colon: if($var == -ms-input-placeholder, ":", "::");
		&#{$_colon}#{$var} {
			color: #C4C4C4;
		}
	}
	&.-sizeSS {
		max-width: 95px;
	}
	&.-sizeM {
		max-width: 600px;
	}
}
textarea {
	height: 360px;
}

/* radio
-------------*/
input[type="radio"] {
	@include accessibilityHidden;
	& + * {
		cursor: pointer;
		display: inline-block;
		padding-left: 40px;
		position: relative;
		width:100%;
		&::before,
		&::after {
			border-radius: 100%;
			content: '';
			position: absolute;
			top: 50%;
			transition: all .2s;
		}
		&::before {
			border: 1px solid #C4C4C4;
			height: 25px;
			left: 0;
			margin-top: -12px;
			width: 25px;
			@include tabletP {
				
			}
		}
		&::after {
			background: $mainColor;
			height: 13px;
			left: 6px;
			margin-top: -6px;
			opacity: 0;
			transform: scale(0.5);
			width: 13px;
		}
		&:hover {
			&::before {
				background: #FEFEFE;
			}
		}
	}
	&.error {
		& + * {
			&::before{
				background-color:rgb(255, 242, 242);
				border: 1px solid $colorStrong;
			}
		}
	}
	&:checked {
		& + * {
			// &::before {
			// 	background: #fff;
			// 	border: 1px solid $colorBlue;
			// }
			&::after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
}

/* checkbox
-------------*/
input[type="checkbox"] {
	@include accessibilityHidden;
	& + * {
		cursor: pointer;
		display: inline-block;
		padding: 3px 10px 3px 22px;
		position: relative;
		transition: all .2s;
		&::before,
		&::after {
			content: '';
			position: absolute;
		}
		&::before {
			border: 1px solid #C4C4C4;
			border-radius: 3px;
			height: 14px;
			left: 0;
			margin-top: -8px;
			top: 50%;
			width: 14px;
		}
		&::after {
			border-bottom: 2px solid $mainColor;
			border-left: 2px solid $mainColor;
			height: 4px;
			left: 3px;
			margin-top: -4px;
			opacity: 0;
			top: 50%;
			transform: rotate(-45deg) scale(0.5);
			width: 8px;
		}
		&:hover {
			&::before {
				background: #fff;
			}
		}
	}
	&:checked {
		& + * {
			// &::before {
			// 	background: #fff;
			// 	border: 1px solid $colorBlue;
			// }
			&::after {
				opacity: 1;
				transform: rotate(-45deg) scale(1);
			}
		}
	}
}
