@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";
/*
#overview
modBtn
*/

/*
#styleguide
modBtn01

```
```
*/
.modBtn01 {
    $_: &;
    background-color: $mainColor;
    border: 2px $mainColor solid;
    border-radius: 27px / 50%;
    display: block;
    position: relative;
    color: #fff;
    font-family: $baseFontFamily;
    text-align: center;
    text-decoration: none;
    box-shadow: 0px 3px 6px rgba(109, 30, 10, 0.16);
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    padding: 10px 10px 12px;
    @include tabletP {
        max-width: 315px;
    }
    &::after {
        content: "";
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        width: 9px;
        height: 9px;
        margin: auto;
        transform: rotate(45deg);
    }
    &.-color02 {
        background-color: #FAF8F4;
        border-color: #FFBE6E;
        box-shadow: 0px 3px 10px rgba(200, 189, 182, 0.16);
        color: $mainColor;
        letter-spacing: 0;
        &::after {
            border-top-color: $mainColor;
            border-right-color: $mainColor;
        }
    }
    &.-color03 {
        background-color: $mainBgColor;
        border: 1px solid #fff;
        box-shadow: none;
    }
    &.-sizeS {
        @include fz(14);
        max-width: 200px;
        padding: 9px 10px 11px;
        &::after {
            width: 7px;
            height: 7px;
        }
    }
    &.-sizeL {
        max-width: 285px;
        padding: 11px 10px 11px;
    }
    &.-alignLeftPc {
        @include tabletPmin {
            margin-left: 0;
        }
    }
    &.-alignRightPc {
        @include tabletPmin {
            margin-right: 0;
        }
    }
}//.modBtn01

/*
#styleguide
modBtn02

```
```
*/
.modBtn02 {
    $_: &;
    background-color: #FAF8F4;
    border: 2px #FFBE6E solid;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(109, 30, 10, 0.16);
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @include fz(14);
    font-family: $baseFontFamily;
    font-weight: 600;
    line-height: math.div(21, 14);
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 11px 34px 11px 14px;
    &::before,
    &::after {
        content: "";
    }
    &::before {
        background-color: $mainColor;
        mask-position: center;
        mask-size: 100%;
        mask-repeat: no-repeat;
        flex-shrink: 0;
    }
    &::after {
        border-top: 2px solid $mainColor;
        border-right: 2px solid $mainColor;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 12px;
        width: 9px;
        height: 9px;
        margin: auto;
        transform: rotate(45deg);
    }
    &.-web {
        &::before {
            mask-image: url(#{$cmnPath}icon_web.svg);
            width: 37px;
            height: 37px;
        }
    }
    &.-form {
        &::before {
            mask-image: url(#{$cmnPath}icon_form.svg);
            width: 28px;
            height: 37px;
        }
    }
    &.-arrowNone {
        padding-left: 8px;
        padding-right: 12px;
        &::after {
            content: none;
        }
    }
}//.modBtn02

/*
#styleguide
modBtnSample

```
```
*/
.modBtnSample {

}//.modBtnSample