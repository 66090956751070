@use "mixin" as *;
@use "setting" as *;
@use "sass:math";

@charset "utf-8";

p {
    & + & {
        margin-top: 1.8em;
        @include tabletP {
            margin-top: 15px;
        }
    }
}
ul:not([class]) {
    li {
        display: flex;
        &::before {
            content: "";
            background-color: $mainColor;
            display: inline-block;
            flex-shrink: 0;
            width: 9px;
            height: 9px;
            transform: rotate(45deg);
            margin: .6em 17px 0 0;
            @include tabletP {
                width: 8px;
                height: 8px;
            }
        }
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
}
.wp-block-image {
    margin: 0;
    @at-root figure#{&} {
        &:not(:last-child) {
            margin-bottom: 50px;
            @include tabletP {
                margin-bottom: 26px;
            }
        }
    }
    figure {
        margin: 0;
    }
    figcaption {
        margin: 0;
    }
    .alignleft {
        max-width: 47%;
        margin: 0 math.percentage(math.div(51px, 1000px)) 50px 0;
        @include tabletP {
            float: none;
            max-width: none;
            width: 100%;
            margin-bottom: 26px;
            margin-right: 0;
        }
        img {
            @include tabletP {
                width: 100%;
            }
        }
    }
    .aligncenter {
        margin-bottom: 50px;
        @include tabletP {
            width: 100%;
            margin-bottom: 26px;
        }
        img {
            @include tabletP {
                width: 100%;
            }
        }
    }
    .alignright {
        max-width: 47%;
        margin: 0 0 50px math.percentage(math.div(51px,1000px));
        @include tabletP {
            float: none;
            max-width: none;
            width: 100%;
            margin-bottom: 26px;
            margin-left: 0;
        }
        img {
            @include tabletP {
                width: 100%;
            }
        }
    }
    &:not(:last-child) {
        margin-top: 40px;
    }
    h2 + &,
    h3 + &,
    h4 + &,
    h5 + &,
    h6 + & {
        margin-top: 0 !important;
    }
}
.wp-block-media-text {
    $_: &;
    & &__media {
        @at-root #{$_}.has-media-on-the-right #{$_}__media {
        }
        @at-root #{$_}.-spImage #{$_}__media {
            @include tabletP {
                display: none;
            }
        }
    }
    & &__content {
        padding: 0;
        .-spImage__image {
            margin-bottom: 30px;
            @include tabletPmin {
                display: none;
            }
            figure {
                float: none !important;
                margin: 0 !important;
            }
            img {
                width: 100%;
            }
        }
    }
}
.wp-block-columns {
    margin-bottom: 0;
}

/*
<figure class="wp-block-gallery columns-2～3 is-cropped">
	<ul class="blocks-gallery-grid">
		<li class="blocks-gallery-item">
			<figure>画像</figure>
		</li>
	</ul>
</figure>
*/
.wp-block-gallery {
    $_: &;
    :root & {
        &:not(:first-child) {
            margin-top: 60px;
            @include tabletP {
                margin-top: 30px;
            }
        }
        &:not(:last-child) {
            margin-bottom: 30px;
            @include tabletP {
                margin-bottom: 15px;
            }
        }
        .blocks-gallery-item {
            @include tabletPmin {
                flex: 1;
                margin: 0 0 30px;
            }
            &:not(:last-child) {
                @include tabletPmin {
                    margin-right: 31px;
                }
            }
        }
    }
}
figure {
    img {
        border-radius: 10px;
        box-shadow: 0px 3px 10px rgba(200, 189, 182, 0.16);
    }
    &.-radiusNone {
        img {
            border-radius: 0;
        }
    }
    &.-shadowNone {
        img {
            box-shadow: none;
        }
    }
}
