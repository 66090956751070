@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";
#footer {}
.gFooter {
    $_: &;
    background-color: $mainBgColor;
    @include print{
        display: none;
    }
    @include tabletP {
        padding-bottom: $sideHeightSp;
    }
    &__container {
        @include tabletP {
            padding-left: ($containerPadding)+px;
            padding-right: ($containerPadding)+px;
        }
    }
    &-group {
        $_group: &;
        &.-group01 {
            padding: 90px 0 90px;
            @include tabletP {
                padding: 15px 0 0;
            }
            #{$_}__container {
                padding-right: ($containerPadding)+px;
            }
            #{$_group}__inner {
                @include tabletPmin {
                    max-width: 100%;
                    width: 1230px;
                }
            }
        }
        &__inner {
            display: flex;
            justify-content: space-between;
            @include tabletP {
                display: block;
            }
        }
        &.-group02 {
            @include tabletP {
                background: linear-gradient(rgba(#000, .8), rgba(#000, .8)), url(#{$cmnPath}bg_footer_address_sp.jpg) no-repeat center bottom / calc(100% + 22px);
            }
        }
    }
    &__nav {
        width: 474px;
        margin-top: -7px;
        @include tabletP {
            width: auto;
            margin: 0 0 30px;
        }
    }
    &__bnrlist {
        width: calc(100% - 507px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include tabletP {
            max-width: 345px;
            width: auto;
            margin-left: auto;
            margin-right: auto;
        }
        &::before {
            content: "";
            display: block;
            order: 1;
        }
    }
    &__bnrlist::before,
    &__bnr {
        width: math.percentage(math.div(220px,473px));
        margin-bottom: 15px;
        @include max(860) {
            width: 220px;
            @include tabletPmin {
                margin-left: auto;
                margin-right: auto;
            }
        }
        @include tabletP {
            width: calc(50% - 7.5px);
        }
        img {
            width: 100%;
        }
    }
    &-schedule {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        @include tabletPmin {
            background: linear-gradient(rgba(#000, .8), rgba(#000, .8)), url(#{$cmnPath}bg_footer_address.jpg) no-repeat 0 center / cover;
        }
        @include tabletP {
            display: block;
            width: auto;
        }
        &__inner {
            @include tabletPmin {
                max-width: 490px+$containerPadding;
                width: 100%;
                padding: 0 #{$containerPadding}px;
            }
            > * > * {
                @include tabletPmin {
                    margin-left: 0 !important;
                }
            }
        }
    }
    &__map {
        order: 1;
        position: relative;
        width: 50%;
        @include tabletP {
            width: auto;
            min-height: 208px;
            margin: 0 -#{$containerPadding}px;
            padding-top: math.percentage(math.div(208px,375px));
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__copyright {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        @include fz(14);
        line-height: math.div(28,14);
        text-align: center;
        height: 76px;
        @include tabletP {
            justify-content: flex-start;
            @include fz(12);
            line-height: math.div(28,12);
            height: 56px;
        }
    }
}

.gFNav {
    $_: &;
    &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include tabletP {
            display: block;
        }
    }
    &__item {
        color: #fff;
        @include fz(14);
        line-height: 1.4;
        width: calc(50% - 16.5px);
        @include tabletP {
            width: auto;
        }
        &::after {
            content: "";
            background: linear-gradient(to right, #fff, #fff 1px, transparent 1px, transparent 5px) repeat-x 0 0 / 5px 1px;
            display: block;
            height: 1px;
        }
    }
    &__link {
        position: relative;
        color: inherit;
        text-decoration: none;
        height: 100%;
        padding: 18px 35px 18px 15px;
        @include tabletPmin {
            display: flex;
            align-items: center;
        }
        @include tabletP {
            display: block;
        }
        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &::before {
            border: 1px solid #fff;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            right: 14px;
        }
        &::after {
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            width: 5px;
            height: 5px;
            right: 20px;
            transform: rotate(45deg);
        }
        &-word {
            display: block;
            &.-small {
                font-size: math.div(13px, 14px) * 1em;
            }
        }
    }
}//.gFnav

.gPageTop {
    background-color: $mainColor;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 76px;
    height: 76px;
    transition: .2s;
    z-index: 3;
    @include tabletP {
        bottom: $sideHeightSp;
        width: 56px;
        height: 56px;
        }
    &[aria-hidden = "true"] {
        opacity: 0;
        pointer-events: none;
    }
    &::before {
        content: "";
        border-top: 3px solid #fff;
        border-left: 3px solid #fff;
        display: block;
        width: 14px;
        height: 14px;
        margin-top: 6px;
        transform: rotate(45deg);
        @include tabletP {
            width: 12px;
            height: 12px;
            margin-top: 4px;
        }
    }
}//.gPageTop
