@use "../mixin" as *;
@use 'sass:math';

@charset "utf-8";

/*
#overview
modTxt
*/

/*
#styleguide
modTxt01

```
```
*/
.modTxt01 {
    & + & {
        margin-top: math.div(36,20)*1em;
        @include tabletP {
            margin-top: math.div(15,16)*1em;
        }
    }
    &Bold {
        font-weight: bold;
    }
}
.modTxtInfo01 {
    @include fz(14);
    letter-spacing: 0.05em;
    margin-top: 30px;
    @include tabletP {
        margin-top: 15px;
    }
}
.modTxtLead01 {
    line-height: math.div(40,16);
    text-align: center;
    margin-bottom: 45px;
    @include tabletP {
        line-height: math.div(32,16);
        text-align: left;
        margin-bottom: 20px;
    }
}
.modTxtSign01 {
    color: #333;
    @include fz(14);
    line-height: math.div(25,14);
    text-align: right;
    margin-top: 56px;
    @include tabletP {
        margin-top: 26px;
    }
}