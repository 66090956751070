@use "../layout/footer";
@use "../mixin" as *;
@use "../setting" as *;
@use 'sass:math';

@charset "utf-8";
/*
#overview
modBlock
*/

/*
#styleguide
modBlockSchedule

```
```
*/
.modBlockSchedule {
    $_: &;
    background-color: rgba($mainColor, .8);
    border-radius: 10px;
    color: #fff;
    font-family: $fontMincho;
    text-align: center;
    max-width: 426px;
    padding: 25px 30px 34px;
    @include tabletP {
        border-radius: 0;
        max-width: none;
        margin: 0 -#{$containerPadding}px;
        padding: 24px #{$containerPadding}px 34px;
    }
    &__inner {
        max-width: 366px;
        margin: 0 auto;
    }
    &.-type02 {
        background: none;
        @include tabletPmin {
            text-align: left;
            padding: 90px 0 90px;
        }
        @include tabletP {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    &__address {
        margin-bottom: 10px;
        @include tabletP {
            @include fz(14);
            margin-bottom: 5px;
        }
    }
    &__tel {
        display: inline-flex;
        align-items: center;
        @include fz(32);
        font-weight: bold;
        line-height: math.div(46,32);
        margin-bottom: 12px;
        @include tabletP {
            @include fz(28);
            margin-bottom: 9px;
        }
        &::before {
            content: "";
            background: url(#{$cmnPath}icon_tel.svg) no-repeat center / contain;
            width: 30px;
            height: 30px;
            margin: 1px 9px 0 0;
            @include tabletP {
                width: 25px;
                height: 25px;
            }
        }
        a {
            color: inherit;
        }
    }
    &__fax {
        @include fz(18);
        line-height: math.div(28,18);
        margin-top: -12px;
        margin-bottom: 15px;
        @include tabletP {
            @include fz(14);
            line-height: math.div(28,14);
        }
        #{$_}.-type02 & {
            @include tabletPmin {
                margin-bottom: 27px;
            }
        }
    }
    &__item {
        display: block;
        @include fz(14);
        line-height: math.div(20,14);
        thead,
        tbody,
        tfoot {
            display: block;
        }
        tr {
            display: flex;
            width: 100%;
            > * {
                display: block;
                text-align: center;
                white-space: nowrap;
                padding: 10px 10px 10px;
                &:not(:first-child) {
                    flex-shrink: 0;
                    width: calc(1.3em + 20px);
                    @include tabletP {
                        width: calc(1.25em + 18px);
                    }
                }
                &:first-child {
                    width: 100%;
                    padding-left: 20px;
                }
                &:last-child {
                    width: calc(1.3em + 30px);
                    padding-right: 20px;
                    @include tabletP {
                        width: calc(1.25em + 28px);
                    }
                }
            }
            sup {
                position: absolute;
                font-size: 0.6em;
            }
        }
        thead {
            tr {
                background-color: rgba(#fff, .2);
                border-color: #fff;
                border-style: solid;
                border-width: 1px 0;
            }
        }
        tbody {
            tr {
                &:not(:last-child) {
                    border-bottom: 1px dotted #fff;
                }
            }
        }
    }
    &__closed {
        @include fz(14);
        font-weight: 500;
        line-height: math.div(28,14);
        text-align: right;
        margin-bottom: 11px;
        @include tabletP {
            text-align: center;
        }
        @at-root #{$_}.-type02 & {
            text-align: inherit;
            margin-bottom: 0;
        }
    }
    &__btnlist {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        max-width: 340px;
        margin: 13px auto 0;
    }
}//.modBlockSchedule

/*
#styleguide
modBlockSchedule02

```
```
*/
.modBlockSchedule02 {
    table {
        position: relative;
        tr {
            > * {
                text-align: center;
                @include tabletP {
                    @include fz(14);
                    line-height: math.div(20,14);
                }
            }
            > *:first-child {
                width: 34%;
            }
        }
        thead {
            tr {
                > * {
                    background-color: #FAF8F4;
                    color: #555;
                    font-weight: bold;
                    letter-spacing: 0.1em;
                    padding: 28px 10px 28px;
                    @include tabletP {
                        padding: 18px 10px 17px;
                    }
                }
            }
        }
        tbody {
            tr {
                &::after {
                    content: "";
                    background: linear-gradient(to right, #999999, #999999 1px, transparent 1px, transparent 5px) repeat-x 0 0 / 5px 1px;
                    display: block;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 1px;
                }
                > * {
                    padding: 28px 10px 27px;
                    @include tabletP {
                        padding: 16px 10px 15px;
                    }
                }
                td {
                    color: #FFBE6E;
                }
                &:first-child {
                    &::after {
                        bottom: 0;
                    }
                }
                sup {
                    position: absolute;
                    font-size: 0.6em;
                }
            }
        }
    }
    &__closed {
        text-align: right;
        margin-top: 12px;
        @include tabletP {
            @include fz(14);
            text-align: center;
            margin-top: 10px;
        }
    }
    &.-type02 {
        table {
            @include tabletP {
                display: grid;
                thead,
                tbody {
                    display: contents;
                }
                tr {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: subgrid;
                    grid-row: 1 / span 7;
                    th,
                    td {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: auto;
                    }
                }
                th,
                td {
                    padding: 16px 9px 15px;
                }
            }
        }
    }
}//.modBlockSchedule02

/*
#styleguide
modBlockStaff

```
```
*/
.modBlockStaff {
    $_: &;
    & + & {
        margin-top: 90px;
        @include tabletP {
            margin-top: 60px;
        }
    }
    &__name {
        border-bottom: 1px solid $mainColor;
        @include fz(22);
        font-family: $fontMincho;
        line-height: math.div(28,22);
        margin-bottom: 60px;
        padding-bottom: 24px;
        @include tabletP {
            @include fz(16);
            line-height: math.div(28,16);
            margin-bottom: 30px;
            padding-bottom: 17px;
        }
        &Bold {
            font-size: (math.div(30px,22px))*1em;
            @include tabletP {
                font-size: (math.div(20px,16px))*1em;
            }
        }
    }
    &-list {
        &.-type02 {
            background-color: #FAF8F4;
            padding: 60px 60px 60px;
            @include tabletP {
                margin: 0 -#{$containerPadding}px;
                padding: 30px #{$containerPadding}px 30px;
            }
        }
        &__group {
            &:not(:last-child) {
                margin-bottom: 60px;
                @include tabletP {
                    margin-bottom: 30px;
                }
            }
        }
        &__title {
            background-color: rgba(#fff, .7);
            border: 1px solid #FFBE6E;
            display: inline-block;
            color: $mainColor;
            @include fz(22);
            font-family: $fontMincho;
            font-weight: 600;
            letter-spacing: 0.1em;
            line-height: math.div(31,22);
            margin-bottom: 10px;
            padding: 5px 37px 5px;
            @include tabletP {
                @include fz(18);
                line-height: math.div(26,18);
                margin-bottom: 0;
                padding: 5px 41px 5px;
            }
        }
        &__item {
            display: flex;
            align-items: center;
            position: relative;
            padding: 20px 0 21px;
            @include tabletP {
                display: block;
            }
            &:not(:first-child) {
                &::after {
                    content: "";
                    background: linear-gradient(to right, #999999, #999999 1px, transparent 1px, transparent 5px) repeat-x 0 0 / 5px 1px;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                }
            }
        }
        &__date {
            width: 170px;
            @include tabletP {
                @include fz(14);
                line-height: 1.4;
                width: auto;
                margin-bottom: 7px;
            }
        }
        &__txt {
            width: 100%;
        }
    }
}//.modBlockStaff

/*
#styleguide
modBlock01

```
```
*/
.modBlock01 {
    $_: &;
    display: flex;
    justify-content: space-between;
    margin-bottom: 54px;
    @include tabletP {
        display: block;
        margin-bottom: 30px;
    }
    & > figure {
        width: math.percentage(math.div(430px,980px));
        @include tabletP {
            text-align: center;
            width: auto;
            margin-bottom: 30px !important;
        }
        @at-root #{$_}.has-media-on-the-right > figure {
            order: 1;
        }
        @at-root #{$_}.-size02 > figure {
            @include tabletPmin {
                width: math.percentage(math.div(490px,980px));
            }
        }
        img {
            @include tabletP {
                width: 100%;
            }
        }
    }
    & > div {
        &:not(:only-child) {
            width: math.percentage(math.div(490px,980px));
            @include tabletP {
                width: auto;
            }
            @at-root #{$_}.-size02 > div {
                @include tabletPmin {
                    width: math.percentage(math.div(430px,980px));
                }
            }
        }
    }
    &__btn {
        margin-top: 30px;
    }
    // 画像のfloat表示
    &.-type02 {
        @include tabletPmin {
            display: block;
        }
        &:not(.has-media-on-the-right) > figure {
            @include tabletPmin {
                float: left;
                margin: 0 60px 40px 0;
            }
        }
        &.has-media-on-the-right > figure {
            @include tabletPmin {
                float: right;
                margin: 0 0 40px 60px;
            }
        }
        & > div {
            width: 100%;
        }
    }
}

/*
#styleguide
modBlock02

```
```
*/
.modBlock02 {
    $_: &;
    background-color: #FAF8F4;
    padding: 60px 60px 60px;
    @include tabletPmin {
        display: flex;
        justify-content: space-between;
    }
    @include tabletP {
        margin: 0 -15px;
        padding: 30px 15px 30px;
    }
    &__image {
        order: 1;
        width: calc(100% - 414px);
        @include tabletP {
            text-align: center;
            width: auto;
            margin-bottom: 15px;
        }
        img {
            @include tabletP {
                width: 100%;
            }
        }
    }
    &__detail {
        flex-shrink: 0;
        width: 355px;
        @include tabletP {
            width: auto;
        }
    }
    &__txt {
        margin-bottom: 30px;
        @include tabletP {
            margin-bottom: 15px;
        }
    }
}//.modBlock02

/*
#styleguide
modBlockAuthor

```
```
*/
.modBlockAuthor {
    $_author: ".modBlockAuthor";
    // background-color: #FAF8F4;
    position: relative;
    margin-bottom: 120px;
    // padding: 50px 60px 60px;
    @include tabletP {
        margin-bottom: 60px;
        // margin: 0 -#{$containerPadding}px 30px;
        // padding: 30px 15px 30px;
    }
    &__head {
        border-bottom: 1px solid $mainColor;
        margin-bottom: 60px;
        padding-bottom: 30px;
        @include tabletP {
            margin-bottom: 30px;
            padding-bottom: 15px;
        }
    }
    &__affiriation,
    &__name {
        font-family: $fontMincho;
        @include tabletP {
            @include fz(14);
            line-height: math.div(28,14);
        }
    }
    &__affiriation {
        @include tabletP {
            margin-bottom: 5px;
        }
    }
    &__name {
        &Bold {
            font-size: (math.div(24px,16px))*1em;
            @include tabletP {
                font-size: (math.div(20px,14px))*1em;
            }
        }
    }
    &__txt {
        @include tabletP {
            @include fz(14);
            line-height: math.div(28,14);
        }
        & + #{$_author}__txt {
            margin-top: 1em;
            @include tabletP {
                margin-top: 0.5em;
            }
        }
    }
    &__btn {
        @include tabletPmin {
            
            position: absolute;
            top: 10px;
            right: 0;
            width: 100%;
        }
        @include tabletP {
            margin-top: 30px;
        }
    }
}//.modBlockAuthor

/*
#styleguide
modBlockDetail

```
```
*/
.modBlockDetail {
    $_: &;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include tabletP {
        display: block;
    }
    &__image {
        position: relative;
        width: 550px;
        margin-right: -430px;
        z-index: 1;
        @include tabletP {
            width: auto;
            margin: 0 -#{$containerPadding}px #{-30px};
        }
        &:not(.-type02) {
            @include tabletPmin {
                order: 1;
                margin-left: -430px;
                margin-right: 0;
            }
        }
        img {
            @include tabletP {
                border-radius: 0;
                width: 100%;
            }
        }
    }
    &__detail {
        background-color: #FAF8F4;
        position: relative;
        width: 550px;
        padding: 60px 60px 60px;
        z-index: 2;
        @include tabletP {
            width: auto;
            padding: 30px #{$containerPadding}px 30px;
        }
    }
    &__title {
        color: $mainColor;
        font-family: $fontMincho;
        @include fz(22);
        font-weight: 600;
        line-height: math.div(31,22);
        margin-bottom: 30px;
        @include tabletP {
            @include fz(18);
            line-height: math.div(26,18);
            margin-bottom: 15px;
        }
        &::after {
            content: "";
            background-color: $mainColor;
            display: block;
            width: 120px;
            height: 2px;
            margin-top: 15px;
        }
    }
    &__btn {
        margin-top: 30px;
        > * {
            @include tabletPmin {
                margin-left: 0;
            }
        }
    }
}//.modBlockDetail

/*
#styleguide
modBlockSlide

```
```
*/
.modBlockSlide {
    $_: &;
    & > *:not(.modBlockSlide-nav) {
        padding: 0 96px;
        @include tabletP {
            padding: 0;
        }
        #{$_}__item {
            margin: 0 auto;
            img {
                width: 100%;
            }
            figcaption {
                margin-top: 30px;
                @include tabletP {
                    margin-top: 20px;
                }
            }
        }
    }
    &-nav {
        margin: 60px -15px 0;
        @include tabletP {
            margin: 20px -5px 0;
        }
        &.-scrollNone {
            .slick-track {
                transform: translate3d(0, 0, 0) !important;
            }
        }
        &__item {
            position: relative;
            padding: 0 15px;
            @include tabletP {
                padding: 0 5px;
            }
            &::after {
                content: "";
                background: rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                opacity: 0;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 15px;
                right: 15px;
                transition: .2s;
                cursor: pointer;
                @include tabletP {
                    border-radius: 5px;
                    left: 5px;
                    right: 5px;
                }
                @at-root .slick-current & {
                    opacity: 1;
                }
            }
            img {
                @include tabletP {
                    border-radius: 5px;
                }
            }
        }
    }
    .slick-arrow {
        background: #faf8f4;
        border: 2px solid #ffbe6e;
        box-shadow: 0px 3px 4px rgba(199, 168, 146, 0.16);
        border-radius: 50%;
        position: absolute;
        top: 0;
        width: 60px;
        height: 0;
        margin-top: calc(22% - 30px);
        padding-top: 56px;
        overflow: hidden;
        z-index: 2;
        @include tabletP {
            width: 45px;
            margin-top: calc(28% - 22.5px);
            padding-top: 41px;
        }
        &::before {
            content: "";
            border-top: 2px solid $mainColor;
            border-right: 2px solid $mainColor;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 12px;
            height: 12px;
            margin: auto;
            @include tabletP {
                width: 9px;
                height: 9px;
            }
        }
    }
    .slick-prev {
        left: 0;
        &::before {
            left: 4px;
            right: 0;
            transform: rotate(-135deg);
            @include tabletP {
                left: 3px;
                right: 0;
            }
        }
    }
    .slick-next {
        right: 0;
        &::before {
            left: 0;
            right: 4px;
            transform: rotate(45deg);
            @include tabletP {
                left: 0;
                right: 3px;
            }
        }
    }
    
}//.modBlockSlide

/*
#styleguide
modBlockCatch

```
```
*/
.modBlockCatch {
    $_: &;
    margin-bottom: 54px;
    @include tabletP {
        margin-bottom: 60px;
    }
    &__catch {
        color: $mainColor;
        @include fz(32);
        font-family: $fontMincho;
        font-weight: 600;
        line-height: math.div(46,32);
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 60px;
        @include tabletP {
            @include fz(20);
            line-height: math.div(29,20);
            margin-bottom: 19px;
        }
    }
    &__txt {
        line-height: math.div(32,16);
        text-align: center;
        @include tabletP {
            line-height: math.div(28,16);
            text-align: left;
        }
        & + & {
            margin-top: (math.div(32,16))*1em;
            @include tabletP {
                margin-top: (math.div(11,16))*1em;
            }
        }
    }
}//.modBlockCatch

/*
#styleguide
modBlockAccess

```
```
*/
.modBlockAccess {
    $_: &;
    @include tabletPmin {
        display: grid;
        grid-template:
            "modBlockAccess__title modBlockAccess__image" auto
            "modBlockAccess__address modBlockAccess__image" auto
            "modBlockAccess__btn modBlockAccess__image" auto / math.percentage(math.div(430px,980px)) 1fr;
    }
    & &__title {
        grid-area: modBlockAccess__title;
        margin-top: 6px;
        @include tabletP {
            margin: 0 0 30px;
        }
    }
    & &__image {
        grid-area: modBlockAccess__image;
        position: relative;
        margin: 0 0 0 60px;
        @include tabletP {
            margin: 0 0 20px;
        }
        figure {
            float: none;
            width: 100%;
        }
    }
    & &__address {
        grid-area: modBlockAccess__address;
        @include tabletP {
            margin-bottom: 25px;
        }
    }
    & &__btn {
        grid-area: modBlockAccess__btn;
        margin-top: 55px;
        @include tabletP {
            margin-top: 21px;
        }
    }
}//.modBlockAccess

/*
#styleguide
modBlockXXX

```
```
*/
.modBlockXXX {
    $_: &;
}//.modBlockXXX
