@use "../mixin" as *;
@use "../setting" as *;

@charset "utf-8";

/*
#overview
other
*/

/*
#styleguide
xxxxx

```
コード
```
*/


/*
#styleguide
modTel

```
<a href="tel:0862756768" class="modTel">086-275-6768</a>
```
*/
.modTel {
	@include tabletPmin {
		pointer-events: none;
	}
}

@keyframes rotateLoading {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(180deg) scale(0.6);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}


/*↓消さないこと*/
.modLoading {
	background-color:rgba(255,255,255,0.8);
	bottom:0;
	left:0;
	position:fixed;
	right:0;
	top:0;
	&__inner{
		bottom: 0;
		height: 42px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top:0;
		width: 42px;
		&::after {
			@include animation(rotateLoading,0.75s,linear,infinite);
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
			background: transparent !important;
			background-color: #666;
			border: 2px solid #666;
			border-bottom-color: transparent;
			border-radius: 100%;
			content: '';
			display: inline-block;
			height: 40px;
			margin: 2px;
			width: 40px;
		}
	}


}
/*↑消さないこと*/

.modSection01 {
	&:not(:last-child) {
		margin-bottom: 120px;
		@include tabletP {
			margin-bottom: 60px;
		}
	}
}//.modSection01

.modSection02 {
	&:not(:first-child) {
		margin-top: 90px;
		@include tabletP {
			margin-top: 60px;
		}
	}
	.modTitle01 + & {
		margin-top: 0;
	}
}//.modSection02

.modBox01 {
    $_: &;
    background-color: #FAF8F4;
    padding: 60px 60px 60px;
    @include tabletP {
        margin: 0 -#{$containerPadding}px;
        padding: 30px #{$containerPadding}px 30px;
    }
    &:not(:first-child) {
        margin-top: 60px;
        @include tabletP {
            margin-top: 30px;
        }
    }
	&:not(:last-child) {
		margin-bottom: 60px;
		@include tabletP {
			margin-bottom: 30px;
		}
	}
}//.modBox01

.modBox02 {
    $_: &;
    background-color: #FAF8F4;
    padding: 30px 30px 30px;
    @include tabletP {
        margin: 0 -#{$containerPadding}px;
        padding: 30px #{$containerPadding}px 30px;
    }
    &:not(:first-child) {
        margin-top: 30px;
        @include tabletP {
            margin-top: 18px;
        }
    }
	&:not(:last-child) {
		margin-bottom: 30px;
		@include tabletP {
			margin-bottom: 30px;
		}
	}
}//.modBox01

.modAnchor {
	position: absolute;
	margin-top: -($headerHeight);
	@include tabletP {
		margin-top: -($headerHeightSp);
	}
}